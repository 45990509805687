<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1 class="ml-2" style="font-weight: 200">
            Elementos De Costos por Tonelada
          </h1>
        </div>

        <div></div>

        <div class="p-4 ml-2 ml-4">
          <code>
            {{ searchText }}
          </code>
          <v-text-field v-model="searchText"> </v-text-field>
        </div>
        <v-data-table
          dense
          :options="{ itemsPerPage: 15 }"
          multi-sort
          sort-by="name"
          :headers="[
            { text: 'Nombre', value: 'name', sortable: false },
            { text: 'Unidad de Medida', value: 'unitMeasure', sortable: false },
            { text: 'Impuesto', value: 'tax', sortable: false },
            { text: '', value: 'actions', sortable: false },
          ]"
          :items="filteredDirectSliceCostItems"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5 mb-2"
                      >Editar "{{ dialogTitle }}"
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <div
                        class="
                          d-flex
                          justify-center
                          pa-1
                          flex-column flex-lg-row flex-xl-row
                        "
                      >
                        <v-text-field
                          label="Other Ton Cost Name"
                          dense
                          class="mr-2"
                          v-model="otherTonCostItem.name"
                        >
                        </v-text-field>
                        <v-select
                          label="Unidad de Medida"
                          v-model="otherTonCostItem.unitMeasure"
                          dense
                          class="mr-2"
                          :value="{ value: 'tn', name: 'tn' }"
                          :items="[{ value: 'tn', name: 'tn' }]"
                          item-text="name"
                          item-value="value"
                        >
                        </v-select>

                        <v-text-field
                          label="Impuesto (0-100)%"
                          dense
                          append-icon="mdi-percent-outline"
                          class="mr-2"
                          type="number"
                          v-model.number="otherTonCostItem.tax"
                        >
                        </v-text-field>
                      </div>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeDialog">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="saveOtherTonCostItem"
                    >
                      <v-icon> mdi-floppy</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="800px">
                <v-card>
                  <v-card-title class="text"
                    >¿Esta seguro que desea eliminar "{{
                      deleteDialogTitle
                    }}"?</v-card-title
                  >
                  <v-card-subtitle
                    >Para eliminar una categoria de Costo por Tonelada, antes
                    debe asegurarse que no se encuentra en uso tanto en
                    <strong>Campañas</strong> como <strong>Borradores</strong>.
                  </v-card-subtitle>
                  <v-card-text>
                    <v-container>
                      <div
                        class="
                          d-flex
                          justify-center
                          pa-1
                          flex-column flex-lg-row flex-xl-row
                        "
                      >
                        <v-text-field
                          label="Other Ton Cost Name"
                          dense
                          filled
                          readonly
                          outlined
                          class="mr-2"
                          v-model="otherTonCostItemToDelete.name"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Other Ton Cost Name"
                          dense
                          filled
                          readonly
                          outlined
                          class="mr-2"
                          v-model="otherTonCostItemToDelete.unitMeasure"
                        >
                        </v-text-field>
                        <v-text-field
                          label="Impuesto (0-100)%"
                          dense
                          filled
                          append-icon="mdi-percent-outline"
                          outlined
                          readonly
                          class="mr-2"
                          type="number"
                          v-model.number="otherTonCostItemToDelete.tax"
                        >
                        </v-text-field>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeDelete">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="deleteItemConfirm"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
        <div
          class="d-flex justify-center pa-1 flex-column flex-lg-row flex-xl-row"
        >
          <v-text-field
            label="Other Ton Cost Name"
            dense
            class="mr-2"
            v-model="newOtherTonItem.name"
          >
          </v-text-field>
          <v-select
            label="Unidad de Medida"
            v-model="newOtherTonItem.unitMeasure"
            dense
            class="mr-2"
            :value="{ value: 'tn', name: 'tn' }"
            :items="[{ value: 'tn', name: 'tn' }]"
            item-text="name"
            item-value="value"
          >
          </v-select>

          <v-text-field
            label="Impuesto (0-100)%"
            dense
            append-icon="mdi-percent-outline"
            class="mr-2"
            type="number"
            v-model.number="newOtherTonItem.tax"
          >
          </v-text-field>

          <v-btn
            color="primary white--text"
            class="ml-2"
            @click="null /*saveNewOtherTonCostItem*/"
            depressed
            disabled
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";
import useSelectables from "../mixins/useSelectables";
import { deleteRequest, putRequest } from "../managrx/serverCall";

export default {
  //
  mixins: [useSelectables],
  computed: {
    ...mapGetters(["otherTonCostItems"]),
    newOtherTonItemTaxAsDecimal(){
      // (0-100) -> (0-1)
      return this.newOtherTonItem.tax / 100
    },
    otherTonCostItemTaxAsDecimal(){
      // (0-100) -> (0-1)
      return this.otherTonCostItem.tax / 100;
    },
    parsedOtherTonCostItems() {
      return this.otherTonCostItems.map((element) => {
        return { ...element, unitMeasure: "tn" };
      });
    },
    filteredDirectSliceCostItems() {
      let items = this.parsedOtherTonCostItems.filter((costItem) =>
        costItem.name.toLowerCase().startsWith(this.searchText.toLowerCase())
      );

      // this is to show tax in a scale of (0-100)
      return items.map((el) => {
        return { ...el, tax: el?.tax * 100 };
      });
    },
  },

  mounted() {
    this.$store.dispatch("fetchOtherTonCostItems");
    this.$store.dispatch("getAbstractCampaigns");
    this.$store.dispatch("getCampaigns");
  },
  methods: {
    editItem(item) {
      this.dialogTitle = item.name;
      this.otherTonCostItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.deleteDialogTitle = item.name;
      this.otherTonCostItemToDelete = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleteItemConfirm() {
      let id = this.otherTonCostItemToDelete.id;
      let response = await deleteRequest(`/other_ton_cost_item/${id}`);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.otherTonCostItemToDelete = {};
        this.$store.dispatch("fetchOtherTonCostItems");
      }
      this.closeDelete();
    },
    async saveOtherTonCostItem() {
      let id = this.otherTonCostItem.id;
      let payload = {
        name: this.otherTonCostItem.name,
        tax: this.otherTonCostItemTaxAsDecimal,
      };
      let response = await putRequest(`/other_ton_cost_item/${id}`, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.dialog = false;
        this.otherTonCostItem = {};
        this.$store.dispatch("fetchOtherTonCostItems");
      }
    },
    async saveNewOtherTonCostItem() {
      let payload = {
        ...this.newOtherTonItem,
        tax: this.newOtherTonItemTaxAsDecimal,
      };
      let response = await postRequest("/other_ton_cost_item", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.newOtherTonItem = {};
        this.$store.dispatch("fetchOtherTonCostItems");
      }
    },
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      searchText: "",
      dialogTitle: "",
      deleteDialogTitle: "",
      newOtherTonItem: {},
      otherTonCostItemToDelete: {},
      otherTonCostItem: {},
    };
  },
};
</script>
